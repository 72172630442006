import React, { useState, useEffect } from 'react';
import './Detail.css';

export default function Detail(props) {
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		const timeoutHandle = setTimeout(() => {
			setOpacity(props.detail ? 1 : 0);
		}, 150);
		return () => timeoutHandle && clearTimeout(timeoutHandle);
	}, [props.detail]);

	return props.detail ? (
		<div className="container" onClick={props.close}>
			<div className="detail" style={{ opacity }} onClick={e => e.stopPropagation()}>
				<div className="row">
					<div className="title">
						{props.project.title}
					</div>
					<div className="close" onClick={props.close}>
						x
					</div>
				</div>
				<div className="content" dangerouslySetInnerHTML={{ __html: props.project.mainContent }} />
			</div>
		</div>
	) : null;
}
