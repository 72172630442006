import React, { Component } from 'react';
import Projects from './Projects';
import Detail from './Detail';

import projects from './projects.json';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.slugs = projects.map(p => p.slug);

    this.state = {
      slug: null,
      project: {},
      detail: false,
    };

    this.setProject = this.setProject.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  componentDidMount() {
    const [project] = window.location.pathname.replace(/\//g, '').toLowerCase().split('.');
    const slug = project || this.slugs[0];
    const detail = this.slugs.includes(project);
    this.setProject(slug, detail);
  }

  setProject(slug, detail) {
    if (this.slugs.includes(slug)) {
      const project = projects.find(p => p.slug === slug);
      if (detail) {
        window.history.replaceState({}, slug, `/${slug}`);
      }
      this.setState({ slug, project, detail });
    }
  }

  toggleDetail() {
    const detail = !this.state.detail;
    if (!detail) {
      window.history.replaceState({}, '', '/');
    }
    this.setState({ detail });
  }

  render() {
    return (
      <div className="App">
        <div className="about">
          <span className='action'>
            <a href='/'>James Proctor </a>
          </span>
          is a software artist and data visualization designer. 
          See new work on
          <span className='action'>
            <a href='https://www.instagram.com/jprctr/' target='_blank' rel='noopener noreferrer'> instagram </a>
          </span>
          or get in touch through
          <span className='action'>
            <a href='mailto:mail@jamesaproctor.com'> email</a>
          </span>.
        </div>
        <Projects projects={projects} {...this.state} select={this.setProject} />
        {
          <Detail {...this.state} close={this.toggleDetail} />
        }
      </div>
    );
  }
}

export default App;
