import React, { useState, useEffect } from 'react';
import './Projects.css';

function Project(props) {
	const { p, i } = props;
	const [backgroundImage, setBackgroundImage] = useState(null);
	const backgroundImageUrl = `url(./images/${p.previewImage})`;

	useEffect(() => {
		const timeoutHandle = setTimeout(() => {
			setBackgroundImage(backgroundImageUrl);
		}, i * 50);
		return () => clearTimeout(timeoutHandle);
	}, [i, backgroundImageUrl]);

	return (
		<div
			className="project"
			key={p.slug}
			style={{
				// backgroundImage: `url(./images/${previewImage})`
			}}
		>
			<div
				className="cell"
				style={{
					border: props.slug === p.slug ? '4px solid orange' : '1px solid white',
					// backgroundImage: `url(./images/${p.previewImage})`,
					backgroundImage,
					opacity: backgroundImage ? 1 : 0,
				}}
				onMouseOver={() => props.select(p.slug, false)}
				onClick={() => {
					return p.isPost
						? props.select(p.slug, true)
						: window.open(p.slug, '_blank')
				}}
			>
				{p.title}
			</div>
		</div>
	);
}

export default function Projects(props) {
	// const { previewImage } = props.project;
	return (
		<div className="projects" 
			// style={{ backgroundImage: `url(./images/${previewImage})` }}
		>
			{props.projects.map((p, i) => (<Project key={p.slug} {...props} p={p} i={i} />))}
		</div>
	);
}
